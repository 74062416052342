<template>
    <div class="wrap">
        <div class="flex justify-start" style="padding: 20px">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="财务类型">
                    <el-select v-model="searchForm.type" placeholder="请选择">
                        <el-option label="佣金" :value="1"></el-option>
                        <el-option label="提现" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search()">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button plain @click="reset()">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="title" label="说明" />
            <el-table-column prop="money" label="金额" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.money}` }}
                </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" plain v-if="scope.row.type === 1">佣金</el-button>
                    <el-button type="primary" size="mini" plain v-else-if="scope.row.type === 2">提现</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="left" />
        </el-table>
        <div class="text-center margin-top">
            <el-pagination
                @current-change="pageChange"
                :current-page="searchForm.page"
                :page-sizes="pageSizes"
                :page-size="searchForm.page_size"
                layout="prev, pager, next, total, jumper"
                :total="searchForm.total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            searchForm: {
                type: '',
                page: 1,
                page_size: 1,
                total: 0,
            },
            pageSizes: [15],
            loading: false,
            tableData: [],
        };
    },
    created: function() {
        this.search();
    },
    methods: {
        pageChange: function(page) {
            this.searchForm.page = page;
            this.search();
        },
        search: function() {
            this.loading = true;
            let searchForm = this.$util.emptyToUndefined(this.searchForm);
            this.$api.platformActivity.getAccountList(searchForm).then((res) => {
                this.searchForm.total = res.data.total;
                this.tableData = res.data.data;
                this.pageSizes = res.data.per_page.toString().split();
                this.searchForm.page_size = res.data.per_page;
                this.loading = false;
            });
        },
        reset: function() {
            this.searchForm.type = '';
            this.search();
        },
        toDetail: function(id) {
            this.$router.push(`/platformActivity/packageCard/detail/${id}`);
        },
    },
};
</script>
